import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

// Image Imports

import aboutteam from "../../../images/team/aboutteam.svg";
import expertise from "../../../images/team/expertise.svg";
import experience from "../../../images/team/experience.svg";
import approach from "../../../images/team/approach.svg";
import culture from "../../../images/team/culture.svg";
import innovation from "../../../images/team/innovation.svg";
import diversity from "../../../images/team/diversity.svg";
import collaboration from "../../../images/team/collaboration.svg";
import commitment from "../../../images/team/commitment.svg";
import achievements from "../../../images/team/achievements.svg";
import years from "../../../images/team/years.svg";
import projects from "../../../images/team/projects.svg";
import partnered from "../../../images/team/partnered.svg";
import growth from "../../../images/team/growth.svg";
import inclusive from "../../../images/team/inclusive.svg";
import impact from "../../../images/team/impact.svg";
import jointeam from "../../../images/team/jointeam.svg";
import right_arrow_white from "../../../images/right_arrow_white.svg";

// End of Image Imports

const companyCulture = [
  {
    id: 1,
    title: "Innovation and Excellence",
    desc: `We encourage our team to think outside the box and pursue excellence in every project. This drive for innovation keeps us at the forefront of the digital industry, continuously improving our solutions and services.`,
    imgURL: innovation,
    invert: false,
  },
  {
    id: 2,
    title: "Diversity and Inclusion",
    desc: `Our strength lies in our diversity. We believe that diverse perspectives fuel innovation, and we are committed to creating an inclusive environment where everyone feels valued and respected.`,
    imgURL: diversity,
    invert: true,
  },
  {
    id: 3,
    title: "Collaboration and Teamwork",
    desc: `We foster a collaborative environment where ideas are shared openly, and teamwork is encouraged. This ensures that our solutions are well-rounded and reflective of our collective expertise.`,
    imgURL: collaboration,
    invert: false,
  },
  {
    id: 4,
    title: "Commitment to Social Impact",
    desc: `We are dedicated to making a positive impact in our community. Our partnership with Rotary International, through our founder’s involvement as a Rotaractor and a Rotarian, reflects our commitment to social service and community engagement.`,
    imgURL: commitment,
    invert: true,
  },
];

function AboutOurTeam() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About our team • Devship";
  }, []);
  return (
    <section className="about-team-wrapper">
      <div className="container">
        <div className="about-team-header">
          <Row>
            <Col sm="12" lg="6" md="12" className="d-flex align-items-center">
              <div className="about-team-content">
                <h2>Our Team</h2>
                <h6>
                  At Devship, our team is our greatest strength. We are a group
                  of passionate professionals dedicated to delivering
                  exceptional digital solutions. With expertise spanning
                  software development, digital marketing, project management,
                  and technical consulting, our team collaborates to bring your
                  vision to life.
                </h6>
              </div>
            </Col>
            <Col
              sm="12"
              lg="6"
              md="12"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="abt-team-image">
                <img src={aboutteam} alt="About our team" />
              </div>
            </Col>
          </Row>
        </div>
        {/* USP Points */}
        <div className="abtteam-usp-points">
          <div className="abtteam-items-wrap">
            <Row>
              <Col sm="12" lg="6" md="12">
                <div className="abtteam-item">
                  <img src={expertise} alt="Expertise" />
                  <h4>Our Expertise</h4>
                  <h6>
                    Our collective skills and innovative approach ensure we
                    provide comprehensive services tailored to meet your unique
                    business needs. We thrive on solving complex challenges and
                    delivering high-quality results.
                  </h6>
                </div>
                <div className="abtteam-item">
                  <img src={experience} alt="Experience" />
                  <h4>Our Experience</h4>
                  <h6>
                    Our team has successfully worked across diverse industries,
                    including healthcare, finance, retail, and technology. We
                    have a proven track record of helping businesses enhance
                    their operations, improve customer engagement, and drive
                    growth. Our extensive experience enables us to deliver
                    solutions that are both innovative and practical.
                  </h6>
                </div>
              </Col>
              <Col sm="12" lg="6" md="12">
                <div className="abtteam-item down">
                  <img src={approach} alt="Approach" />
                  <h4>Our Approach</h4>
                  <h6>
                    We believe in a client-centric approach that emphasises
                    collaboration and transparency. Our methodology involves
                    understanding your unique needs, crafting tailored
                    solutions, and maintaining open communication throughout the
                    project lifecycle. We employ agile practices to ensure
                    flexibility and responsiveness, adapting to changes swiftly
                    to deliver the best possible outcomes.
                  </h6>
                </div>
                <div className="abtteam-item">
                  <img src={culture} alt="Culture" />
                  <h4>Our Culture and Values</h4>
                  <h6>
                    We foster a culture of continuous learning, collaboration,
                    and excellence. Our values—innovation, integrity, and
                    customer focus—guide everything we do. We believe in a
                    supportive and inclusive environment where every team member
                    can thrive and contribute to our success.
                  </h6>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* Company Culture */}
        <div className="abtteam-culture">
          <div className="abtteam-culture-header">
            <h2>Our Company Culture</h2>
            <h4>Driving Innovation and Excellence</h4>
            <h6>
              At Devship, we are more than just a team; we are a family that
              values creativity, diversity, and a commitment to social
              responsibility. Our culture is built on
            </h6>
          </div>
          <div className="abtteam-culture-points">
            {companyCulture.map((item) => {
              return item.invert === false ? (
                <Row>
                  <Col sm="12" lg="6" md="12">
                    <div className="abtteam-cul-item-image">
                      <img src={item.imgURL} alt="Innovation" />
                    </div>
                  </Col>
                  <Col sm="12" lg="6" md="12">
                    <div className="abtteam-cul-item">
                      <h5>{item.title}</h5>
                      <p>{item.desc}</p>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm="12" lg="6" md="12">
                    <div className="abtteam-cul-item right">
                      <h5>{item.title}</h5>
                      <p>{item.desc}</p>
                    </div>
                  </Col>
                  <Col sm="12" lg="6" md="12">
                    <div className="abtteam-cul-item-image">
                      <img src={item.imgURL} alt="Innovation" />
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
        {/* Achievements */}
        <div className="abtteam-achievements">
          <Row>
            <Col
              sm="12"
              lg="5"
              md="12"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="abtteam-ach-image">
                <img src={achievements} alt="Achievements" />
              </div>
            </Col>
            <Col sm="12" lg="7" md="12">
              <div className="abtteam-ach-item">
                <h2>Achievements and Milestones</h2>
                <ul>
                  <li>
                    <div className="abach-img" style={{ "--bg": "#f4ebff" }}>
                      <img src={years} alt="Years" />
                    </div>
                    <h6>40 years of combined experience across our team.</h6>
                  </li>
                  <li>
                    <div className="abach-img" style={{ "--bg": "#e2ffe8" }}>
                      <img src={projects} alt="Projects" />
                    </div>
                    <h6>
                      Successfully completed 45+ projects across various
                      industries.
                    </h6>
                  </li>
                  <li>
                    <div className="abach-img" style={{ "--bg": "#fff5ee" }}>
                      <img src={partnered} alt="Partnered" />
                    </div>
                    <h6>
                      Partnered with top industry leaders to deliver innovative
                      solutions.
                    </h6>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        {/* Why Join our team? */}
        <div className="why-join-section">
          <h2>Why Join Our Team?</h2>
          <h5>
            At Devship, we’re committed to building a diverse, innovative, and
            supportive workplace. Here’s what you can expect
          </h5>
          <div className="why-join-items-wrap">
            <Row>
              <Col sm="12" md="6" lg="4">
                <div className="wjs-item">
                  <div className="wjs-img">
                    <img src={growth} alt="Growth" />
                  </div>
                  <h6>Growth Opportunities</h6>
                  <p>
                    Work on exciting projects and develop new skills in a
                    dynamic, learning-focused environment.
                  </p>
                </div>
              </Col>
              <Col sm="12" md="6" lg="4">
                <div className="wjs-item">
                  <div className="wjs-img">
                    <img src={inclusive} alt="Inclusive" />
                  </div>
                  <h6>Inclusive Culture</h6>
                  <p>
                    Be part of a team that values diversity, fosters
                    collaboration, and supports your professional and personal
                    growth.
                  </p>
                </div>
              </Col>
              <Col sm="12" md="6" lg="4">
                <div className="wjs-item">
                  <div className="wjs-img">
                    <img src={impact} alt="Impact" />
                  </div>
                  <h6>Make an Impact</h6>
                  <p>
                    Contribute to impactful digital solutions and engage in
                    community service initiatives through our Rotary
                    partnership.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* Join us */}
        <div className="join-us-wrapper">
          <Row>
            <Col sm="12" md="12" lg="6">
              <h2>Join us on our journey</h2>
              <h6>
                We are always looking for talented individuals who share our
                passion for innovation. Interested in learning more? Explore
                Careers with us!
              </h6>
              <Link to="/contact">
                <button className="common-btn">
                  Reach us
                  <div className="common-btn-img">
                    <img src={right_arrow_white} alt="Right" />
                  </div>
                </button>
              </Link>
            </Col>
            <Col
              sm="12"
              md="12"
              lg="6"
              className="d-flex align-items-center justify-content-center"
            >
              <img src={jointeam} className="join-team-img" alt="Join" />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default AboutOurTeam;
